import {Component, Vue} from 'vue-property-decorator';
import {Evaluation} from "../../shared/model/Evaluation";
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {ActivityType} from "../../shared/model/ActivityType";
import {County} from "../../shared/model/County";
import {Status} from "../../shared/model/Status";
import {FiscalYear} from "../../shared/model/FiscalYear";
import {Department} from "../../shared/model/Department";
import {rules} from "../../shared/model/Rules";
import {evalApi} from "@/plugins/axios";


@Component({
    components: {},
})
export default class EvaluationForm extends Vue {
    title = "Evaluation Form"
    maskText = "";
    valid = true;
    evaluation: Evaluation = new Evaluation();
    menu1 = false;
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    activityTypes = ActivityType.values;
    counties = County.values;
    surveyTypes: Array<String> = [];
    statuses = Status.values;
    fiscalYears = new FiscalYear().values
    endDateString: string = "";
    dueDateString: string = "";
    departments = Department.values.sort();
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    public created() {
        this.loadSurveyTypes()
    }

    get computedEndDate(): String {
        return this.formatDate(this.endDateString);
    }

    get computedDueDate(): String {
        return this.formatDate(this.dueDateString);
    }

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    async submitNewEvaluation(): Promise<void> {
        const res = await evalApi.get(`/evaluationsImport/evalNumExists/` + this.evaluation.evaluationNumber)
        if (res.data) {
            this.snackbar.color = "error"
            this.snackbar.text = "Evaluation '" + this.evaluation.evaluationNumber + "' already exists. No duplicates are allowed."
            this.snackbar.visible = true
            return
        }
        evalApi.post("/evaluationsImport/create", {
            providerNumber: this.evaluation.providerNumber,
            providerType: this.evaluation.providerType,
            providerName: this.evaluation.providerName,
            evaluationNumber:this.evaluation.evaluationNumber,
            surveyType:this.evaluation.surveyType,
            department:this.evaluation.department,
            endDate:this.evaluation.endDate,
            dueDate:this.evaluation.dueDate,
            evaluationType:this.evaluation.evaluationType,
            status:this.evaluation.status = "ACTIVE",
            jobName:this.evaluation.jobName = "EvaluationsImport"

        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Evaluation '" + this.evaluation.evaluationNumber + "' has been created."
            this.snackbar.visible = true
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred submitting the evaluation number: >>>> " + error
            this.snackbar.visible = true
        })
    }

    public loadSurveyTypes(): void {
        evalApi.post('/surveyType/getByFlag', {flag: this.evaluation.evaluationType})
            .then((res: any) => {
                this.surveyTypes = res.data;
            })
    }
}